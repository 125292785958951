import { LoadingOutlined } from "@ant-design/icons"
import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { getOnlyDigits } from "@utils/strings"
import { Button, Col, Form, Input } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask"

import styles from "../style.module.less"

const AuthStep = ({
  phoneNumber,
  handleResetStep,
  setLoading,
  loading,
  setStep,
  setErrors,
  onlyValidate,
  handleSuccessOnlyValidate,
  handleErrorOnlyValidate,
  isProfile,
  data,
  setVisible,
  setSuccessMessage,
  isSubscriber,
  setCode,
}) => {
  const [timer, setTimer] = useState(30)
  const [resendDisable, setResendDisable] = useState(false)

  const toResetPassword = window.location.pathname == "/login"

  const { authSite, affiliated, registrationSubscriber } = useStores()

  const { verifyCode, sendCode } = authSite

  const { editProfileCreator } = affiliated

  const { updateSubscriberAnonimatta } = registrationSubscriber

  useEffect(() => {
    if (!timer) {
      setResendDisable(false)
      return
    }

    const interval = setInterval(() => {
      setTimer((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [timer])

  const onClickResend = () => {
    setTimer(30)
    setResendDisable(true)
    sendCode({ phone: getOnlyDigits(phoneNumber), toResetPassword })
  }

  const handleSubmit = async (values) => {
    if (!isProfile) {
      const token = Number(getOnlyDigits(values.code))

      setLoading(true)

      const res = await verifyCode({
        token,
        toResetPassword,
        phoneNumber: getOnlyDigits(phoneNumber),
      })

      setLoading(false)

      const resSuccess = _get(res, "status")

      if (resSuccess === 200) {
        if (onlyValidate) {
          handleSuccessOnlyValidate()
          return
        }
        setStep(4)
        setCode(token)
        return
      }

      if (onlyValidate) {
        handleErrorOnlyValidate()
      }

      const adaptedErrors = errorsAdapter(res)
      setErrors(adaptedErrors)
    } else {
      const token = Number(getOnlyDigits(values.code))

      setLoading(true)

      const res = await verifyCode({ token, toResetPassword })

      setLoading(false)

      const resSuccess = _get(res, "status")

      if (resSuccess === 200) {
        setCode(token)
        if (!isSubscriber) {
          if (onlyValidate) {
            handleSuccessOnlyValidate()
            return
          }
          data.phoneNumber = Number(getOnlyDigits(phoneNumber))
          await editProfileCreator(data.id, data)
          setVisible(false)
          setSuccessMessage(translate("phoneChanged"))
          return
        } else {
          if (onlyValidate) {
            handleSuccessOnlyValidate()
            return
          }
          data.phoneNumber = Number(getOnlyDigits(phoneNumber))
          await updateSubscriberAnonimatta(data.id, data)
          setVisible(false)
          setSuccessMessage(translate("phoneChanged"))
          return
        }
      }

      if (onlyValidate) {
        handleErrorOnlyValidate()
      }

      const adaptedErrors = errorsAdapter(res)
      setErrors(adaptedErrors)
    }
  }

  const validateCode = () => ({
    validator(rule, value) {
      if (value) {
        if (value.length < 7) {
          return Promise.reject(translate("invalidCode"))
        }
      }
      return Promise.resolve()
    },
  })

  const resentText = !resendDisable ? (
    translate("resend")
  ) : (
    <>
      {translate("resend")}&nbsp;{translate("in")}&nbsp;{`${timer}s`}
    </>
  )

  return (
    <>
      <div className={styles.descriptionContainer}>
        <span className={styles.form_title}>
          {onlyValidate || isProfile
            ? translate("verifyNumber")
            : translate("forgotMyPassword")}
        </span>

        <span className={(styles.form_subtitle, styles.marginBottom)}>
          {translate("forgotMyPassword.authDescription")}
        </span>

        <div
          className={cx(
            "flex  items-center justify-center",
            styles.marginBottom
          )}
        >
          <span onClick={handleResetStep} className="french-rose f14 fw5 mr8">
            {phoneNumber}
          </span>

          <Icon name="EditPink" onClick={handleResetStep} />
        </div>
      </div>
      <div className={cx("containerLabels", styles.containerVerifyNumber)}>
        <span className={styles.form_label}>Código </span>
        <Button
          type="text"
          className={cx("", styles.form_link, styles.resendButton)}
          disabled={resendDisable}
          onClick={onClickResend}
        >
          {resentText}
        </Button>
      </div>

      <Form
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          code: null,
        }}
      >
        <Form.Item
          name="code"
          validateStatus={"error"}
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message: translate("requiredInput"),
            },
            validateCode,
          ]}
        >
          <InputMask
            mask="999-999"
            maskChar={null}
            className={cx("self-center ttu tc", styles.authInput)}
          >
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
        </Form.Item>

        <Button
          type="primary"
          className={cx(
            "self-center fw6 f16 mt24",
            styles.buttonSize,
            styles.marginTop
          )}
          htmlType="submit"
          // loading={loading}
        >
          {loading ? (
            <p>
              <LoadingOutlined className={styles.loadingMore} />
            </p>
          ) : (
            translate("continue")
          )}
        </Button>
      </Form>
    </>
  )
}

export default AuthStep
